import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import brukAssets from "./images/brukAssets.gif";
import brukMenu from "./images/brukMenu.gif";
import brukFlateBase from "./images/brukFlateBase.png";
import brukBase from "./images/brukBase.png";
import dontTokens from "./images/dont-tokens.png";
import doTokens from "./images/do-tokens.png";
import primitive from "./images/primitive.png";
import semantic from "./images/semantic.png";
import component from "./images/component.png";
import base from "./images/base.png";
import { BaseCard } from '@entur/layout';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import PageHeader from '~/components/PageHeader';
import { Heading5, SmallText } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <h2 {...{
      "id": "hvorfor-trenger-vi-fargetokens"
    }}>{`Hvorfor trenger vi fargetokens?`}</h2>
    <p>{`Fargetokens er en viktig grunnsten i et designsystem. Ved å lage et sett med farger og fargenavn er tanken at det skal bli lettere for alle å forholde seg til Enturs farger på en korrekt måte. Bruken av tokens gjør det også enklere å vedlikeholde og videreutvikle fargesettet som brukes. Eksempelvis hvis farger skal oppdateres kan det gjøres uten at designsystem-brukere må endre noe på sin side.`}</p>
    <p>{`Med standardiserte fargetokens blir det enkelt og tydelig å endre tilknyttede referansefarger til tokens.
Å bruke ulike fargenavn eller fargeverdier fører til unødvendig ekstra arbeid både ved implementering og bytting av farger i systemet. Dette resulterer i redusert effektivitet og manglende standardisering, spesielt med flere brukere involvert.`}</p>
    <BaseCardDesignEntur arrow mdxType="BaseCardDesignEntur">
  <img src={dontTokens} alt="illustrasjon av at utvikler A bruker navnet Lavender90 og utvikler B bruker navnet ColorBlue men begge er den samme fargen." className="designentur-base-card__image--small" />
    </BaseCardDesignEntur>
    <BaseCardDesignEntur mdxType="BaseCardDesignEntur">
  <img src={doTokens} alt="illustrasjon av at Lavender 90-fargen ved navn Fill.Primary.Default er den man skal bruke i komponenter." className="designentur-base-card__image--small" />
    </BaseCardDesignEntur>
    <h2 {...{
      "id": "tokensstruktur"
    }}>{`Tokensstruktur`}</h2>
    <p>{`Tokensstrukturen inneholder flere lag av fargetokens. Hvert lag i strukturen kommuniserer informasjon om hva fargen representerer, hvordan den skal brukes, og hvor den skal brukes i designsystemet.`}</p>
    <BaseCardDesignEntur arrow title="Primitive tokens" subText="
Det grunnleggende laget består av «primitive tokens», som representerer fargene i fargepaletten." mdxType="BaseCardDesignEntur">
  <img src={primitive} alt="eksempel på en primitive token er lavender 90" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
    <BaseCardDesignEntur arrow title="Semantic tokens" subText="«Semantic tokens» gir en tydeligere mening til fargene ved å tildele dem betydningsfulle navn." mdxType="BaseCardDesignEntur">
  <img src={semantic} alt="eksempel på semantic token er fill-primary-default for bakgrunnen til en firkant, stroke-accent for en strek, text-accent for tekst og shape-accent for et hjerteikon" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={6} mdxType="GridItem">
    <BaseCardDesignEntur title="Component tokens" subText="«Component tokens» spesifiserer fargene som brukes på designsystemets komponenter." mdxType="BaseCardDesignEntur">
      <img src={component} alt="eksempel på en komponent-token er button-primary-fill for bakgrunnen til designsystemets knapp" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
  </GridItem>
  <GridItem small={12} medium={6} mdxType="GridItem">
    <BaseCardDesignEntur title="Base tokens" subText="«Base tokens» spesifiserer fargene som brukes på generelle elementer. Bruke disse på egne komponenter." mdxType="BaseCardDesignEntur">
      <img src={base} alt="eksempel på base-tokens er base-frame-subsueded som er en dempet farge for en bakgrunn til en firkant" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "bruk-fargetokens"
    }}>{`Bruk fargetokens`}</h2>
    <h3 {...{
      "id": "hvilken-tokens-skal-jeg-bruke"
    }}>{`Hvilken tokens skal jeg bruke?`}</h3>
    <p>{`Bruker du kun designsystemets komponenter vil du ikke ha behov for tokens.`}</p>
    <p>{`Om du skal lage noe vi ikke tilbyr via komponenter skal du bruke base-tokens, se `}<a parentName="p" {...{
        "href": "/tokens/fargetokens/oversikt-farger#base"
      }}>{`oversikt over base-tokens`}</a>{`. Base-fargene har støtte for dark-mode. Disse er bygget på «semantic colors» slik at det skal være tydelig hvordan de skal brukes, og hvor de skal brukes. `}</p>
    <p>{`Som allerede nevnt er komponentene i designsystemet integrert med fargetokens, så det eneste som gjenstår for deg er å implementere base-tokens i resten av designløsningen.`}</p>
    <BaseCardDesignEntur title="Eksempel i grensesnitt" mdxType="BaseCardDesignEntur">
    <img src={brukFlateBase} alt="eksemplet viser at background skal brukes for bakgrunn, shape skal brukes på en sirkel, text skal brukes på tekst, og stroke kan brukes på en skillelinje" className="designentur-base-card__image--medium" />
    </BaseCardDesignEntur>
    <BaseCardDesignEntur title="Eksempel komponentnivå" subText="I eksemplet over ser du hvordan du kan lage din egen komponent ved å ta i bruk base-tokens." mdxType="BaseCardDesignEntur">
    <img src={brukBase} alt="eksemplet viser navnene på base-tokens. For eksempel for bakgrunn kan du bruke frame-default, for border kan du bruke stroke-subduedalt, for ikon kan du bruke shape-default, for titteltekst kan du bruke text-default og for subtekst kan du bruke text-subdued" className="designentur-base-card__image--medium" />
    </BaseCardDesignEntur>
    <BannerAlertBox variant="information" title="Hva om base-tokens ikke dekker ditt behov?" mdxType="BannerAlertBox">Om designsystemets komponenter eller base-farger har mangler ønsker vi at du tar kontakt med oss slik at vi kan samarbeide om utvidelser av designsystemets komponent- og base-farger.</BannerAlertBox>
    <h3 {...{
      "id": "bruk-i-figma"
    }}>{`Bruk i Figma`}</h3>
    <p>{`Fargetokensfiler finner du under Assets-menyen i Figma, hvor de enkelt kan slås av eller på etter behov. Vi anbefaler å aktivere fargetokensfilen «Base» først når du bygger design eller grensesnitt. Hvis du skulle ha behov for andre fargetokens ta kontakt med oss.`}</p>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={6} mdxType="GridItem">
    <BaseCardDesignEntur title="Assets meny" subText="I assets meny aktiverer vi Tokens-Base colors" mdxType="BaseCardDesignEntur">
          <img src={brukAssets} alt="" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
  </GridItem>
  <GridItem small={12} medium={6} mdxType="GridItem">
    <BaseCardDesignEntur title="Property meny" subText="Dette bidrar til å unngå for mange fargetokens i property menyen, slik at du kan fokusere bare på det som er nødvendig for gjeldende design." mdxType="BaseCardDesignEntur">
      <img src={brukMenu} alt="velg Token-base i property nedtrekksmenyen" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
  </GridItem>
    </GridContainer>
    <h3 {...{
      "id": "bruk-i-kode"
    }}>{`Bruk i Kode`}</h3>
    <p>{`Alle tokens er tilgjengelige gjennom `}<a parentName="p" {...{
        "href": "/tokens"
      }}>{`Tokens-pakken`}</a>{`. Tokens er organisert i separate filer, dette gjør det enkelt å finne og bruke de spesifikke tokens du trenger. `}</p>
    <p>{`For eksempel, hvis du arbeider med CSS, vil du finne følgende filstruktur i Tokens-pakken:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@entur/tokens/dist/
├── base.css 
├── primitive.css 
├── semantic.css 
├── data.css 
└── transport.css
`}</code></pre>
    <p>{`Eksempel på importering av tokens i CSS og bruk av dem:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import '@entur/tokens/dist/base.css';

.background {
  background-color: var(--basecolors-frame-default);
}
`}</code></pre>
    <p>{`Tokens i SASS og LESS aksesseres med henholdsvis `}<em parentName="p">{`$`}</em>{` og `}<em parentName="p">{`@`}</em>{`, og er semantisk delt opp ved `}<em parentName="p">{`-`}</em>{` (bindestrek).
CSS prefikses med to bindestreker.
Tokens i Javascript aksesseres som nestede objekter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      